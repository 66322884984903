import { Link } from "react-router-dom";

const Navbar = (props) => {
    const title = props.title;
    return ( 
        <div>
            {/* <!-- partial:partials/_navbar.html --> */}
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style={{}}>

                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-start" style={{fontSize:'24px', width: '100%'}}>
                    Admin Dashboard
                </div>
            </nav>
            {/* <!-- partial --> */}
            <nav className="navbar-breadcrumb col-xl-12 col-12 d-flex flex-row p-0">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                    <ul className="navbar-nav mr-lg-2">
                        <li className="nav-item ml-6">
                            <Link className="nav-link" to="/addProduct"><p style={{fontSize: '16px'}} className="mb-0 btn btn-info btn-sm">Add Product
                            </p></Link>
                        </li>
                        <li className="nav-item ml-6">
                            <Link className="nav-link" to="/productList"><p style={{fontSize: '16px'}} className="mb-0 btn btn-info btn-sm">Product List
                            </p></Link>
                        </li>
                        <li className="nav-item ml-6">
                            <Link className="nav-link" to="/"><p style={{fontSize: '16px'}} className="mb-0 btn btn-info btn-sm">Logout</p></Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">

                    </ul>
                </div>
            </nav>

        </div>
     );
}
 
export default Navbar;