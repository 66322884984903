import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import "./GetUrl";
import GetUrl from "./GetUrl";

const Login = () => {
    document.title = "Login";
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const Login = async(e) =>{
        e.preventDefault();
        setIsPending(true);
        setError(false);

        try {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            const res = await axios.post(GetUrl('AdminLogin'), form);
            setIsPending(false);
            if(res.data.info === 'error')
            {
                setError(true);
            }
            else
            {
                setUsername('');
                setPassword('');
                navigate('/productList',{replace:true});
            }

        } catch (error) {
            setIsPending(false);
            setError(true);
        }
    }

    return ( 
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                    <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="brand-logo">
                        <center> <img src="images/keylogo.png" style={{
                            width:'50px',
                            height:'50px'}} alt="logo"/></center>
                    </div>
                    {isPending && <h6 className="font-weight-light" style={{
                        textAlign:'center',
                        marginBottom:'30px'
                    }}>A moment please...</h6>}
                    {error && <h4 className="font-weight-light" style={{
                        textAlign:'center',
                        marginBottom:'30px',
                        color:'red'
                    }}>Access Denied!</h4>}
                    <h4>Login</h4>
                    <form className="pt-3" onSubmit={Login}>
                        <div className="form-group">
                        <input type="text" name="username" className="form-control form-control-lg" id="username" placeholder="Username" value={username} onChange={(e)=> setUsername(e.target.value)} disabled={isPending} required/>
                        </div>
                        <div className="form-group">
                        <input type="password" name="password" className="form-control form-control-lg" id="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} disabled={isPending} required/>
                        </div>
                        <div className="mt-3">
                        {isPending===false && <button type="submit" name="btn" className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn">SIGN IN</button>}
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            </div>
            {/* <!-- page-body-wrapper ends --> */}
        </div>
     );
}
 
export default Login;