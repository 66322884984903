import { Link, useNavigate } from "react-router-dom";

const SideBar = () => {
    const navigate = useNavigate();

    function handleClick() {
        navigate('/',{replace:true});
    }

    return ( 
        // <!-- partial:partials/_sidebar.html -->
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                
                <li className="nav-item">
                    <Link className="nav-link" to="/addProduct">
                        <i className="mdi mdi-logout  menu-icon"></i>
                        <span className="menu-title">Add Product</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/productList">
                        <i className="mdi mdi-logout  menu-icon"></i>
                        <span className="menu-title">Product List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/">
                        <i className="mdi mdi-logout  menu-icon"></i>
                        <span className="menu-title" onClick={handleClick}>Logout</span>
                    </Link>
                </li>
            </ul>
        </nav>
        // <!-- partial -->
        // <!-- Side bar ends -->
     );
}
 
export default SideBar;