import Navbar from './Navbar';
import Footer from "./Footer";
import SideBar from "./SideBar";
import axios from 'axios';
import { useEffect, useState } from 'react';
import GetUrl from './GetUrl';

const PropertyList = () => {
    document.title = "Product List";
    const [productList, setProductList] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [text, setText] = useState('Delete');

    useEffect(()=>{
         const getProductList = async() =>{
            setIsPending(true);
            setError(false);

            try {
                const res = await axios.post(GetUrl("getProductListAdmin"));
                setIsPending(false);
                setProductList([...res.data]);
            } catch (error) {
                setError(true);
            }
        }

        getProductList();
    },[]);

    const handleDelete = async (id, img_name) =>{
        setDeleteId(id);
        setText('Deleting...');
        setIsPending(true);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('img_name', img_name);

            const res = await axios.post(GetUrl("deleteProduct"), formData);
            setIsPending(false);
            if(res.data === 'success')
            {
                const newProduct = productList.filter((product) => product.id !== id);
                setProductList(newProduct);
            }
            else
            {
                setDeleteId(0);
                alert('Oops! Something went wrong. Try again later')
            }
            
        } catch (error) {
            setError(true);
        }
    }
   
    return ( 
        <div>
            <Navbar title={'Product List'} />
            <div className="container-fluid page-body-wrapper">
                <SideBar/>

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="table-responsive pt-3">
                                        <h4 className="card-title" style={{paddingLeft: '30px'}}>Product List</h4>
                                        <table className="table table-striped project-orders-table">
                                            <thead>
                                                <tr>
                                                    {/* <th className="ml-5">S/N</th> */}
                                                    <th>Category</th>
                                                    <th>Product Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productList.length > 0 && 
                                                productList.map((product)=> (
                                                    <tr key={product.id}>
                                                        <td>
                                                            {product.category}
                                                        </td>
                                                        <td>
                                                            {product.product_name}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <input type='button' className="btn btn-danger btn-sm btn-icon-text" value={product.id === deleteId ? text : 'Delete'} onClick={()=>handleDelete(product.id, product.img_name)} disabled={isPending}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                        {error && <div style={{textAlign:'center', padding:'10px', color:'red'}}>Oops! Something went wrong</div>}
                                        {isPending && <div style={{textAlign:'center', padding:'10px', color:'darkblue'}}>a moment please</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <!-- partial:partials/_footer.html --> */}
                    <Footer/>
                    {/* <!-- partial --> */}
                </div>
                {/* <!-- main-panel ends --> */}
            </div>
        </div>
     );
}
 
export default PropertyList;