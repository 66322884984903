import Navbar from './Navbar';
import Footer from "./Footer";
import SideBar from "./SideBar";

const NotFound = () => {
    document.title = "Not Found";
    return ( 
        <div>
            <Navbar title={'Not Found'} />
            <div className="container-fluid page-body-wrapper">
                <SideBar/>

                <div className="main-panel">
                    <div className="content-wrapper">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card" style={{
                                    height:'73vh',
                                    color:'red',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'
                                }}>
                                    <h2>Not Found</h2>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <!-- partial:partials/_footer.html --> */}
                    <Footer/>
                    {/* <!-- partial --> */}
                </div>
                {/* <!-- main-panel ends --> */}
            </div>
        </div>
     );
}
 
export default NotFound;