import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import AddProduct from "./pages/AddProduct";
import ProductList from "./pages/ProductList";

function App() {
  return (
    <div className="container-scroller">
      <Routes>
        <Route exact path="/" element={<Login/>}/>
        <Route path="/addProduct" element={<AddProduct/>}/>
        <Route path="/productList" element={<ProductList/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

export default App;
