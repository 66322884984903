import Navbar from './Navbar';
import Footer from "./Footer";
import SideBar from "./SideBar";
import { useState } from 'react';
import axios from 'axios';
import GetUrl from './GetUrl';

const AddProperty = () => {
    document.title = "Add Product";
    const [productCategory, setProductCategory] = useState('');
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState([]);
    const [imgURl, setImgUrl] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(false);

    const uploadProduct = async(e) =>{
        e.preventDefault();
        setIsPending(true);
        setError(false);

        try {
            if(productCategory === "" || productName === "" || description === '' || file.length === 0)
            {
                alert('Error: Fill in the details correctly!');
            }
            else
            {
                const formData = new FormData();
                formData.append('category', productCategory);
                formData.append('productName', productName);
                formData.append('description', description);
                formData.append('file', file[0]);

                const res = await axios.post(GetUrl('uploadProduct'), formData);
                console.log(res.data);
                setIsPending(false);
                if(res.data.info === 'fail')
                {
                    setError(true);
                }
                else
                {
                    alert(res.data.mesg);
                    setProductCategory('');
                    setProductName('');
                    setDescription('');
                    setFile([]);
                    setImgUrl('');
                }
            }
        } catch (error) {
            setError(true);
        }
    }

    return ( 
        <div>
            <Navbar title={'Add Product'} />
            <div className="container-fluid page-body-wrapper">
                <SideBar/>

                <div className="main-panel">
                    <div className="content-wrapper">

                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        {isPending && <h6 className="font-weight-light" style={{
                                            textAlign:'center',
                                            marginBottom:'30px'
                                        }}>A moment please...</h6>}
                                        {error && <h6 className="font-weight-light" style={{
                                            textAlign:'center',
                                            marginBottom:'30px',
                                            color:'red'
                                        }}>Oops! Something went wrong. Try again</h6>}
                                        <h4 className="card-title">Add Product</h4>
                                        <form className="forms-sample" onSubmit={uploadProduct}>
                                            <div className="form-group">
                                                <label htmlFor="prototype">Product Category</label>
                                                <input type="type" name="productCategory" className="form-control" id="productCategory" placeholder="Product Category" value={productCategory} onChange={(e)=>setProductCategory(e.target.value)} />
                                                {/* <select name="productCategory" id="productCategory" className="form-control" onChange={(e)=>setProductCategory(e.target.value)} disabled={isPending} required>
                                                    <option value="Granite">Granite</option>
                                                    <option value="Marble">Marble</option>
                                                </select> */}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="prototype">Product Name</label>
                                                <input type="type" name="productName" className="form-control" id="productName" placeholder="Product Name" value={productName} onChange={(e)=>setProductName(e.target.value)} disabled={isPending} required/>
                                            </div>
                                             <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <input type="text" name="description" className="form-control" id="description"
                                                    placeholder="Description" value={description} onChange={(e)=>setDescription(e.target.value)} disabled={isPending} required/>
                                            </div>
                                            <div className="form-group">
                                                <label>File upload</label>
                                                <input className="form-control" type="file" name="file" onChange={(e)=>{
                                                    setFile(e.target.files);
                                                    setImgUrl(URL.createObjectURL(e.target.files[0]));
                                                }}  disabled={isPending} required/>
                                                {imgURl && <img src={imgURl} style={{
                                                    height:'300px'
                                                }} alt=""/>}
                                            </div>
                                            
                                            
                                            {isPending===false && <button type="submit" name="btn" className="btn btn-success mr-2">Submit</button>}
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* <!-- partial:partials/_footer.html --> */}
                    <Footer/>
                    {/* <!-- partial --> */}
                </div>
                {/* <!-- main-panel ends --> */}
            </div>
        </div>    
     );
}
 
export default AddProperty;